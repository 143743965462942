import { User } from "firebase";
import Router from "next/router";
import { useRouter } from "next/router";
import React, { Context, createContext, useContext, useEffect, useState } from "react";

import { UserInformation } from "../util/auth";
import { firebaseClient, getFirebaseAnalytics } from "../util/firebase-client";

const authContext = createContext<Auth | null>(null) as Context<Auth>;

interface Auth {
  user: User | false | null;
  userInfo: UserInformation | false | null;
  signIn(email: string, password: string): Promise<User | null>;
  register(email: string, password: string): Promise<User | null>;
  signInGoogle(): void;
  logout(): Promise<void>;
  resetPassword(email: string): Promise<void>;
}

function useProvideAuth(userInfoFromServer: UserInformation | null): Auth {
  const [user, setUser] = useState<User | false | null>(firebaseClient.auth().currentUser);
  const [userInfo, setUserInfo] = useState<UserInformation | false | null>(userInfoFromServer);
  const { pathname } = useRouter();

  const resetPassword = async (email: string) => {
    return firebaseClient.auth().sendPasswordResetEmail(email);
  };

  const signIn = async (email: string, password: string) => {
    const analytics = await getFirebaseAnalytics();
    analytics.logEvent("login", { method: "email-password" });
    return firebaseClient
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((cred) => {
        return cred.user;
      });
  };

  const register = async (email: string, password: string) => {
    const analytics = await getFirebaseAnalytics();
    analytics.logEvent("sign_up", { method: "email-password" });
    const userCredential = await firebaseClient
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await userCredential.user?.sendEmailVerification();
    return userCredential.user;
  };

  const registerGoogle = async () => {
    const analytics = await getFirebaseAnalytics();
    analytics.logEvent("login", { method: "google" });
    firebaseClient.auth().useDeviceLanguage();
    const googleAuthProvider = new firebaseClient.auth.GoogleAuthProvider();
    await firebaseClient.auth().signInWithRedirect(googleAuthProvider);
  };

  const logout = async () => {
    await fetch("/api/auth/logout");
    await firebaseClient.auth().signOut();
    window.postMessage({ type: "LOGOUT_EVENT" }, "*");
    Router.push("/");
    return;
  };

  useEffect(() => {
    let triggeredBySelf = false;
    const unsubscribe = firebaseClient.auth().onIdTokenChanged(async (user) => {
      if (triggeredBySelf) {
        triggeredBySelf = false;
        return;
      }
      if (user) {
        setUser(user);
        if (!userInfo || (userInfo && (userInfo.uid != user.uid || userInfo.email != user.email))) {
          setUserInfo({ uid: user.uid, email: user.email });
        }
        getFirebaseAnalytics().then((analytics) => {
          analytics.setUserId(user.uid);
          analytics.setUserProperties({ userId: user.uid });
        });
        // @ts-ignore
        $crisp.push(["set", "user:email", [user.email]]);
        // @ts-ignore
        $crisp.push(["set", "session:data", [[["userId", user.uid]]]]);
        try {
          const idToken = await user.getIdToken(false);
          const response = await fetch("/api/auth/post-login", {
            headers: {
              Authentication: `Bearer ${idToken}`,
            },
          });
          const body = (await response.json()) as { status: "partial" | "complete" };
          if (body.status == "partial") {
            triggeredBySelf = true;
            await user.getIdToken(true);
            if (pathname !== "/register/step-2") {
              location.href = "/register/step-2";
              Router.replace("/register/step-2");
            }
          } else {
            window.postMessage({ type: "LOGIN_EVENT" }, "*");
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        setUser(false);
        setUserInfo(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [pathname]);

  return {
    user,
    signIn,
    register,
    signInGoogle: registerGoogle,
    logout,
    userInfo,
    resetPassword,
  };
}

export function AuthProvider({
  children,
  userInfo,
}: {
  children: React.ReactNode;
  userInfo?: UserInformation | null;
}) {
  const auth = useProvideAuth(userInfo || null);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
