import "firebase/auth";

import { getFirebaseClientConfig } from "@streamparty/firebase-client-config";
import * as firebaseClient from "firebase/app";

import { REMOTE_CONFIG_DEFAULT } from "../remote-config-default";

export const firebaseConfig = getFirebaseClientConfig(
  process.env.NEXT_PUBLIC_APP_ENV === "production"
);

if (!firebaseClient.apps.length) {
  firebaseClient.initializeApp(firebaseConfig);
}

export const getFirebaseAnalytics = async () => {
  if (!firebaseClient.analytics) {
    await import("firebase/analytics");
  }

  return firebaseClient.analytics();
};

export const getFirebasePerformance = async () => {
  if (!firebaseClient.performance) {
    await import("firebase/performance");
  }

  return firebaseClient.performance();
};

export const getFirebaseRemoteConfig = async () => {
  if (!firebaseClient.remoteConfig) {
    await import("firebase/remote-config");
    firebaseClient.remoteConfig().defaultConfig = REMOTE_CONFIG_DEFAULT;
    await firebaseClient.remoteConfig().fetchAndActivate();
  }

  return firebaseClient.remoteConfig();
};

export { firebaseClient };
